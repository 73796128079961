import { AppConfig } from '@digilize/shared/definitions/src';

export const appConfig: AppConfig = {
  app: 'games-client',
  appTitle: 'Games',
  email: '',
  gtm: '',
  localStorageAuthPrefix: 'gac-',
  logo: {
    light: '/logo/logo-light-dig.svg',
    dark: '/logo/logo-dark-dig.svg',
    mark: '/logo/logo-mark-dig.svg',
  },
  language: {
    defaultLanguage: 'nl',
    languages: [{ code: 'nl', iconFlagPath: 'assets/flags/netherlands.png' }],
  },
  disabledAuthPopup: true,
};

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './landing.component';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '../../components/shared.module';
import { SharedUiComponentsModule } from '@digilize/shared/ui/components/src';
import { OrderNumberComponent } from './order-number/order-number.component';
const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
];
@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes), SharedModule, SharedUiComponentsModule],
  declarations: [LandingComponent, OrderNumberComponent],
})
export class LandingModule {}

import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TitleStrategy } from '@angular/router';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ApiInterceptor } from '@digilize/shared/feature/modules/auth/src';
import { GlobalErrorHandlerWithLog, GlobalErrorHandler } from '@digilize/shared/feature/modules/error-handling/src';
import { CustomTitleWithSuffixStrategyService, I18nModule } from '@digilize/shared/feature/modules/i18n/src';
import { ENV_CONFIG, APP_CONFIG } from '@digilize/shared/utils/tokens/src';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { appConfig } from '../environments/app.config';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainModule } from './views/main.module';
import localeNl from '@angular/common/locales/nl';
import { TranslocoRootModule } from './transloco-root.module';

registerLocaleData(localeNl, 'nl-NL');
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    I18nModule,
    InlineSVGModule.forRoot({ baseUrl: '/assets' }),
    AppRoutingModule,
    MainModule,
    RecaptchaV3Module,
    TranslocoRootModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: environment.production ? GlobalErrorHandlerWithLog : GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB',
    },
    {
      provide: LOCALE_ID,
      useValue: 'nl-NL',
    },
    {
      provide: ENV_CONFIG,
      useValue: environment,
    },
    {
      provide: APP_CONFIG,
      useValue: appConfig,
    },
    { provide: TitleStrategy, useClass: CustomTitleWithSuffixStrategyService },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

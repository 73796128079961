import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gac-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit {
  @Input() smallVariant = false;
  constructor() {}

  ngOnInit() {}
}

import { Injectable } from '@angular/core';
import { ShopQuizzesApiService } from '@digilize/shared/data/services/src';
import { ShopQuizWithShop } from '@digilize/shared/definitions/src';
import { environment } from '@gac/src/environments/environment';
import { BehaviorSubject, filter } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ShopQuizService {
  private _shopQuiz = new BehaviorSubject<ShopQuizWithShop>(null);
  shopQuiz$ = this._shopQuiz.asObservable().pipe(filter((value) => !!value));

  constructor(private shopQuizzesApiService: ShopQuizzesApiService) {
    this.setShop();
  }

  setShop() {
    const permalink = environment.production ? window.location.hostname : 'jumbo-game.com';

    this.shopQuizzesApiService.resolverByDomain(permalink).subscribe((shopQuiz) => {
      this._shopQuiz.next(shopQuiz);
    });
  }
}

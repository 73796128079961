<span>
  {{ order
  }}<ng-container [ngSwitch]="order">
    <ng-container *ngSwitchCase="order === 1">{{ 'order_number.1' | transloco }}</ng-container>
    <ng-container *ngSwitchCase="order === 2">{{ 'order_number.2' | transloco }}</ng-container>
    <ng-container *ngSwitchCase="order >= 3 && order <= 7">{{ 'order_number.3-7' | transloco }}</ng-container>
    <ng-container *ngSwitchCase="order === 8">{{ 'order_number.8' | transloco }}</ng-container>
    <ng-container *ngSwitchCase="order >= 9 && order <= 19">{{ 'order_number.9-19' | transloco }}</ng-container>
    <ng-container *ngSwitchDefault>{{ 'order_number.rest' | transloco }}</ng-container>
  </ng-container>
</span>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Prize, ShopQuizWithShop } from '@digilize/shared/definitions/src';
import { addWebp } from '@digilize/shared/utils/helpers/src';
import { ShopQuizService } from '../../services/shopQuiz.service';
import { first } from 'rxjs';

@Component({
  selector: 'gac-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {
  timeInterval;
  expired: boolean;
  shopQuiz: ShopQuizWithShop;
  time = { days: '--', hours: '--', minutes: '--', seconds: '--' };
  orderedPrizes: Prize[];
  rulesHTML: string;
  shopQuiz$ = this.shopQuizService.shopQuiz$;
  constructor(public shopQuizService: ShopQuizService) {
    this.shopQuiz$.pipe(first()).subscribe((shopQuiz) => {
      this.startCounting(shopQuiz.finish_answers || shopQuiz.finish);
      this.setRules(shopQuiz.quiz.rules);
    });
  }

  ngOnInit() {}

  getOrderedPrizes(shopQuiz: ShopQuizWithShop): Prize[] {
    return (this.orderedPrizes = shopQuiz.prizes.sort((a, b) => a.order - b.order));
  }

  startCounting(finish) {
    this.timeInterval = setInterval(() => {
      const msPast = this.getMsPastTime(finish);
      this.expired = msPast < 0;
      if (this.expired) {
        clearInterval(this.timeInterval);
      }

      this.setTime(Math.trunc(msPast / 1000));
    }, 500);
  }

  getMsPastTime(pastTime: Date): number {
    return new Date(pastTime).getTime() - new Date().getTime();
  }

  setRules(string) {
    if (string.indexOf('<!--more-->') !== -1) {
      this.rulesHTML = string.substring(0, string.indexOf('<!--more-->'));
    } else {
      this.rulesHTML = string;
    }
  }

  setTime(time: number): void {
    if (time < 0) {
      this.time = { days: '00', hours: '00', minutes: '00', seconds: '00' };
    } else {
      const daysRemaining = Math.floor(time / 86400);
      const hoursRemaining = Math.floor((time % 86400) / 3600);
      const minutesRemaining = Math.floor((time % 3600) / 60);
      const secondsRemaining = time % 60;
      const days = this.patchWithZero(daysRemaining);
      const hours = this.patchWithZero(hoursRemaining);
      const minutes = this.patchWithZero(minutesRemaining);
      const seconds = this.patchWithZero(secondsRemaining);

      this.time = { days, hours, minutes, seconds };
    }
  }

  patchWithZero(value: number): string {
    if (value < 10) {
      return `0${value}`;
    } else {
      return `${value}`;
    }
  }

  get addWebp() {
    return addWebp();
  }

  ngOnDestroy(): void {
    clearInterval(this.timeInterval);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiDataResponse,
  AuctionBid,
  AuctionMessage,
  Category,
  DataApiResponseLegacy,
  Event,
  ExternalFeedProduct,
  Group,
  MarketplaceAuction,
  Page,
  PageType,
  Product,
  ProductRequest,
  Shop,
} from '@shared/definitions';

@Injectable({
  providedIn: 'root',
})
export class ShopApiService {
  readonly shopBaseUrl = '/api/v1.0/shops';
  constructor(private http: HttpClient) {}

  // Category

  getCategories = () => this.http.get<Category[]>('/api/v1.0/categories');

  // Shop

  getShops = (params?) => this.http.get<DataApiResponseLegacy<Shop[]>>(this.shopBaseUrl, { params: params });

  getShop = (id: string) => this.http.get<Shop>(`${this.shopBaseUrl}/${id}`);
  getShopPublic = (id: string) => this.http.get<Shop>(`${this.shopBaseUrl}/${id}/public`);

  getShopByPermalink = (permalink: string) => this.http.get<Shop>(`${this.shopBaseUrl}/${permalink}`);

  getShopsByCategoryPermalink = (permalink: string, params?) =>
    this.http.get(`/api/v1.0/categories/${permalink}/shops`, { params });

  addShop = (shop: any) => this.http.post(this.shopBaseUrl, shop);

  updateShop = (shop: any) => this.http.put(this.shopBaseUrl, shop);

  setActiveShop = (id: string, active: boolean) =>
    this.http.put(`${this.shopBaseUrl}/activate`, { id: id, active: active });

  checkPaymentToken = (token: string) =>
    this.http.get(`${this.shopBaseUrl}/payment-methods?token`, { params: { key: token } });

  validateShops = (shopIds: string[]) => this.http.post('${this.shopBaseUrl}/validate', { ids: shopIds });

  getEvent(shopId: string) {
    return this.http.get<Event>(`${this.shopBaseUrl}/${shopId}/event`);
  }

  getEventByShopPermalink(permalink: string) {
    return this.http.get<Event>(`${this.shopBaseUrl}/${permalink}/event`);
  }
  // Product

  getProducts = (params?) => this.http.get<DataApiResponseLegacy<Product[]>>('/api/v1.0/products', { params: params });

  getLikedProducts = (params?) =>
    this.http.get<DataApiResponseLegacy<Product[]>>('/api/v1.0/products/liked', { params: params });

  getProductsByShopPermalink = (permalink, withDescription = false) => {
    const params = withDescription ? { include: 'description' } : {};
    return this.http.get<DataApiResponseLegacy<Product[]>>(`${this.shopBaseUrl}/${permalink}/products`, {
      params: params,
    });
  };

  getProductsByGroupPermalink = (permalink) =>
    this.http.get<DataApiResponseLegacy<Product[]>>(`/api/v1.0/groups/${permalink}/products`);

  getHighlightedProducts = () => this.http.get<Product[]>('/api/v1.0/products/highlighted');

  getVoucherProducts = () => this.http.get<DataApiResponseLegacy<Product>>('/api/v1.0/products/by-vouchers');

  getProduct = (id: string) => this.http.get(`/api/v1.0/products/${id}`);

  getProductByShopPermalink = (permalink: string, id: string) =>
    this.http.get(`${this.shopBaseUrl}/${permalink}/products/${id}`);

  addProduct = (product: any) => this.http.post('/api/v1.0/products', product);

  updateProduct = (product: any) => this.http.put('/api/v1.0/products', product);

  setActiveProduct = (id: string, active: boolean) =>
    this.http.put('/api/v1.0/products/activate', { id: id, active: active });

  getProductMakers = (key: string) => this.http.get<string[]>(`/api/v1.0/products/makers?search=${key}`);

  sendRequestInfo = (productRequest: ProductRequest) => this.http.post('/api/v1.0/forms/info', productRequest);

  addProductLike = (id: string) => this.http.post(`/api/v1.0/products/${id}/like`, { id });

  removeProductLike = (id: string) => this.http.delete(`/api/v1.0/products/${id}/like`);

  getFeedProducts = (params) =>
    this.http.get<ApiDataResponse<ExternalFeedProduct[]>>(`/api/v1.0/product-feeds/entries/search`, { params });

  exportProducts = (companyId: string) =>
    this.http.get(`/api/v1.0/products/export?company=${companyId}`, { responseType: 'blob' });

  importProducts = (form: FormData, headers?: any) =>
    this.http.post(`/api/v1.0/products/import`, form, { headers: headers });
  getPublicUpsellProducts = (id: string) =>
    this.http.get<DataApiResponseLegacy<Product[]>>(`/api/v1.0/products/${id}/upsell`);

  // Events

  getEventsByShopPermalink = (permalink: string) =>
    this.http.get<DataApiResponseLegacy<Event[]>>(`${this.shopBaseUrl}/${permalink}/events`);

  // Taxes

  getTaxes = () => this.http.get('/api/v1.0/taxes');

  // Groups

  getGroups = () => this.http.get<Group[]>('/api/v1.0/groups');

  // Search

  search = (term: string) => this.http.get(`/api/v1.0/search?term=${term}`);

  searchGroups = (term: string) =>
    this.http.get<{ term: string; result: DataApiResponseLegacy<Group[]> }>(`/api/v1.0/groups/search?term=${term}`);

  searchProducts = (term: string, params?) =>
    this.http.get(`/api/v1.0/products/search?term=${term}`, { params: params });

  searchShops = (term: string, params?) => this.http.get(`${this.shopBaseUrl}/search?term=${term}`, { params: params });

  // Pages

  connectShopToPage = (shopId: string, page: Page, pageType: PageType) =>
    this.http.put(`${this.shopBaseUrl}/${shopId}/${pageType}`, page);

  searchAuctions = (term: string, params?) =>
    this.http.get(`/api/v1.0/auctions/search?term=${term}`, { params: params });

  // Marketplace

  getAuctions = (params?) => this.http.get('/api/v1.0/auctions', { params: params });

  getLikedAuctions = (params?) => this.http.get('/api/v1.0/auctions/liked', { params: params });

  getOwnedAuctions = (params?) => this.http.get('/api/v1.0/auctions/owned', { params: params });

  getAuction = (id: string) => this.http.get(`/api/v1.0/auctions/${id}`);

  addAuction = (auctionRequest: MarketplaceAuction) => this.http.post('/api/v1.0/auctions', auctionRequest);

  editAuction = (auctionRequest: MarketplaceAuction) => this.http.put('/api/v1.0/auctions', auctionRequest);

  addAuctionLike = (id: string) => this.http.post(`/api/v1.0/auctions/${id}/like`, { id });

  removeAuctionLike = (id: string) => this.http.delete(`/api/v1.0/auctions/${id}/like`);

  getAuctionBids = (id: string) => this.http.get(`/api/v1.0/auctions/${id}/bids`);

  addAuctionBid = (id: string, bidRequest: AuctionBid) => this.http.post(`/api/v1.0/auctions/${id}/bids`, bidRequest);

  removeAuctionBid = (id: string) => this.http.delete(`/api/v1.0/auctions/bids/${id}/remove`);

  declineAuctionBid = (id: string) => this.http.delete(`/api/v1.0/auctions/bids/${id}/decline`);

  sendAuctionMessage = (id: string, messageRequest: AuctionMessage) =>
    this.http.post(`/api/v1.0/auctions/${id}/messages`, messageRequest);

  setAuctionStatus = (id: string, status: number) =>
    this.http.put(`/api/v1.0/auctions/${id}/status/${status}`, { status });

  deleteAuction = (id: string) => this.http.delete(`/api/v1.0/auctions/${id}`);
}

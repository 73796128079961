import { Component, OnInit } from '@angular/core';
import { ShopQuizWithShop } from '@digilize/shared/definitions/src';
import { Observable } from 'rxjs';
import { ShopQuizService } from '../../services/shopQuiz.service';

@Component({
  selector: 'gac-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  shopQuiz$: Observable<ShopQuizWithShop> = this.shopQuizService.shopQuiz$;
  constructor(private shopQuizService: ShopQuizService) {}

  ngOnInit() {}
}

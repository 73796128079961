import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedUiComponentsModule } from '@digilize/shared/ui/components/src';
import { SharedUiMaterialModule } from '@digilize/shared/ui/material/src';
import { InlineSVGModule } from 'ng-inline-svg-2';

import { SharedModule } from '../components/shared.module';
import { LandingModule } from './landing/landing.module';

const dashboardRoutes: Routes = [
  {
    path: '',
    loadChildren: () => LandingModule, // not lazy loaded as its first view for non logged user
  },
  {
    path: 'rules',
    loadChildren: () => import('./rules/rules.module').then((m) => m.RulesModule),
  },
  {
    path: 'bets',
    loadChildren: () => import('./bets/bets.module').then((m) => m.BetsModule),
  },
  {
    path: 'leaderboard',
    loadChildren: () => import('./leaderboard/leaderboard.module').then((m) => m.LeaderboardModule),
  },
  {
    path: '**',
    redirectTo: '/',
  },
];
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedUiMaterialModule,
    SharedUiComponentsModule,
    InlineSVGModule,
    SharedModule,
    RouterModule.forChild(dashboardRoutes),
  ],
})
export class MainModule {}

<ng-container *ngIf="shopQuiz$ | async as shopQuiz">
  <gac-navbar></gac-navbar>
  <img [src]="shopQuiz.banner?.url1x + '?m=2' + addWebp" alt="Banner background" class="banner" />

  <div class="landing panel">
    <h2 *ngIf="!expired">{{ 'landing.play_the_game' | transloco }}</h2>
    <h2 *ngIf="expired">{{ 'global.check_results' | transloco }}</h2>
    <div class="timer">
      <div class="col">
        <span>{{ time.days }}</span
        ><span>{{ 'landing.days' | transloco }}</span>
      </div>
      <div class="col"><span>:</span><span></span></div>
      <div class="col">
        <span>{{ time.hours }}</span
        ><span>{{ 'landing.hours' | transloco }}</span>
      </div>
      <div class="col"><span>:</span><span></span></div>
      <div class="col">
        <span>{{ time.minutes }}</span
        ><span>{{ 'landing.minutes' | transloco }}</span>
      </div>
      <div class="col"><span>:</span><span></span></div>
      <div class="col">
        <span>{{ time.seconds }}</span
        ><span>{{ 'landing.seconds' | transloco }}</span>
      </div>
    </div>

    <a *ngIf="expired" [routerLink]="['/leaderboard', shopQuiz.id]" class="check-btn">
      <button class="btn basic w-lg h-lg check-btn">{{ 'global.check_results' | transloco }}</button>
    </a>
    <a
      *ngIf="!expired"
      [routerLink]="['/bets', shopQuiz.id, shopQuiz.require_code ? 'enter-code' : 'predictions']"
      class="check-btn"
    >
      <button class="btn secondary w-lg h-lg">{{ 'global.play_now' | transloco }}</button>
    </a>
    <div class="container-overflow steps-info">
      <div class="row" *ngFor="let step of shopQuiz.quiz.steps">
        <h1>{{ step.order + 1 }}</h1>
        <div>
          <h4>{{ step.title }}</h4>
          <p>{{ step.text }}</p>
        </div>
      </div>
    </div>
    <h2>{{ 'global.rules' | transloco }}</h2>
    <div class="rules" [innerHTML]="rulesHTML"></div>

    <h4 class="read-more">
      <a routerLink="/rules">{{ 'landing.read_more_rules' | transloco }}</a>
    </h4>
    <h2 class="prize-title">{{ 'landing.prizes_overview' | transloco }}</h2>
    <!--     <p class="prize-description">some text here</p> -->
    <div class="container-overflow prizes-container">
      <div class="prize" *ngFor="let prize of getOrderedPrizes(shopQuiz); let i = index">
        <gac-badge>
          {{ i + 1 }}
        </gac-badge>
        <gac-order-number [order]="i + 1"></gac-order-number>
        <h3>{{ prize.name }}</h3>
        <p class="amount">{{ prize.count }}x {{ 'landing.prizes' | transloco }}</p>
        <div class="img-wrapper">
          <img [src]="prize.photo?.url1x + '?m=2' + addWebp" [alt]="prize.name + 'image'" />
        </div>
        <h4 *ngIf="prize.url">
          <a [href]="prize.url" target="_blank">
            {{ 'landing.learn_more' | transloco }}
          </a>
        </h4>
      </div>
    </div>

    <a *ngIf="expired" [routerLink]="['/leaderboard', shopQuiz.id]" class="check-btn">
      <button class="btn basic w-lg h-lg check-btn">{{ 'global.check_results' | transloco }}</button>
    </a>
    <a
      *ngIf="!expired"
      [routerLink]="['/bets', shopQuiz.id, shopQuiz.require_code ? 'enter-code' : 'predictions']"
      class="check-btn"
    >
      <button class="btn secondary w-lg h-lg">{{ 'global.play_now' | transloco }}</button>
    </a>
  </div>
  <gac-footer></gac-footer>
</ng-container>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FacebookPages, LoginForm, Me, PageType, PossibleApiPrefix, RegisterForm, Token } from '@shared/definitions';
import { BehaviorSubject, Observable } from 'rxjs';

import { MeApiService } from './me-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  me: BehaviorSubject<Me> = new BehaviorSubject<Me>(null);
  BASE_HREF_AUTH = '/api/v1.0/auth';
  BASE_HREF_AUTH2 = '/api/v2.0/auth';

  constructor(private http: HttpClient, private meApiService: MeApiService) {}

  // User should be logged in until refresh_token is not expired
  getMe = (callback?) => {
    this.meApiService.getMe().subscribe(
      (response: any) => {
        this.setMe(response);
        if (callback) {
          callback();
        }
      },
      (error) => {
        this.setMe(null);
        if (callback) {
          callback();
        }
      }
    );
  };

  setMe = (me: Me) => {
    this.me.next(me);
  };

  getPages(pageType: PageType, code: string, redirectUrl: string) {
    return this.http.get<FacebookPages>(`/api/v2.0/${pageType}/pages?code=${code}&redirectUri=${redirectUrl}`);
  }

  login = (loginForm: LoginForm, captchaHeaders?: HttpHeaders) =>
    this.http.post(this.BASE_HREF_AUTH, loginForm, { headers: captchaHeaders || {} });

  register = (registerForm: RegisterForm, captchaHeaders?: HttpHeaders) =>
    this.http.post(`${this.BASE_HREF_AUTH}/create`, registerForm, { headers: captchaHeaders || {} });

  refreshToken(token: Token, apiPrefix: PossibleApiPrefix = 'api') {
    return this.http.post(`${this.BASE_HREF_AUTH.replace('api', apiPrefix)}/refresh`, token);
  }

  activateProfile = (headers: HttpHeaders) => this.http.get(`${this.BASE_HREF_AUTH}/activate`, { headers });

  activateProfileAndSendMailWithCode = (headers: HttpHeaders) =>
    this.http.get(`${this.BASE_HREF_AUTH}/codeactivate`, { headers });

  activateProfileWithToken = (req: { activate_token: string }, headers: HttpHeaders) =>
    this.http.post(`${this.BASE_HREF_AUTH}/activate`, req, { headers });

  activateProfileWithCode = (req: { code: string }, headers: HttpHeaders) =>
    this.http.post(`${this.BASE_HREF_AUTH2}/code`, req, { headers });

  passwordForgot = (email: string, captchaHeaders?: HttpHeaders) =>
    this.http.post(`${this.BASE_HREF_AUTH}/password-forgot`, { email: email }, { headers: captchaHeaders || {} });

  authWithCode(code: string, captchaHeaders?: HttpHeaders): Observable<Token> {
    return this.http.post<Token>(`${this.BASE_HREF_AUTH}/code`, { code }, { headers: captchaHeaders || {} });
  }

  authRequest(email: string) {
    return this.http.post(`${this.BASE_HREF_AUTH}/code/request`, { email });
  }

  doesEmailExists(email: string, captchaHeaders?: HttpHeaders) {
    return this.http.post<{ exists: boolean }>(
      `${this.BASE_HREF_AUTH}/exists`,
      { email },
      { headers: captchaHeaders || {} }
    );
  }
  // Claim cart session

  claimCartSession = (sessionId: string) => this.http.post(`/api/v1.0/sessions/${sessionId}/claim`, {});

  // Facebook login

  fbLogin = (fbCode: string, redirect: string) =>
    this.http.get(`${this.BASE_HREF_AUTH}/facebook?code=${fbCode}&redirect_uri=${redirect}`);
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Companies,
  Company,
  CompanyMenu,
  CompanyStyles,
  ContactUsers,
  InviteValidate,
  OpenFormLink,
  SendInvites,
  UserLabels,
  UserLabelsBulk,
  UsersPaged,
} from '@shared/definitions';

@Injectable({
  providedIn: 'root',
})
export class CompanyApiService {
  constructor(private http: HttpClient) {}

  addCompany = (newCompany: Company) => this.http.post('/api/v1.0/companies', newCompany);

  updateCompany = (companyForm: Company) => this.http.put<Company>(`/api/v1.0/companies/`, companyForm);

  getCompanies = () => this.http.get<Companies>('/api/v1.0/companies');

  getCompany = (companyId: string) => this.http.get<Companies>(`/api/v1.0/companies/${companyId}`);

  getCompanyStyles = (companyId: string) => this.http.get<CompanyStyles>(`/api/v1.0/companies/styles/${companyId}`);

  getCompanyStylesByDomain = () => this.http.get<CompanyStyles>(`/api/v1.0/companies/styles`);

  getUsersPaged = (params) =>
    this.http.get<UsersPaged>(`/api/v1.0/companies/userspaged`, {
      params,
    });

  contactUsers = (req: ContactUsers) => this.http.post('/api/v1.0/companies/users/contact', req);

  sendInvites = (req: SendInvites) => this.http.post('/api/v1.0/companies/invites', req);

  validatesInvites = (req: InviteValidate) => this.http.post('/api/v1.0/companies/invites/validate', req);

  addLabelToUser = (companyId: string, params: UserLabels) =>
    this.http.post(`/api/v1.0/companies/${companyId}/userlabels`, params);

  removeLabelsFromUser = (companyId: string, params: UserLabels) =>
    this.http.delete(`/api/v1.0/companies/${companyId}/userlabels`, { body: params });

  addBulkLabelsToUser = (companyId: string, params: UserLabelsBulk) =>
    this.http.post(`/api/v1.0/companies/${companyId}/userlabels/bulk`, params);

  removeBulkLabelsFromUser = (companyId: string, params: UserLabelsBulk) =>
    this.http.delete(`/api/v1.0/companies/${companyId}/userlabels/bulk`, { body: params });

  postOpenFormLink = (params: OpenFormLink) => this.http.post(`/api/v1.0/companies/open-form-link`, params);

  putOpenFormLink = (req: OpenFormLink) => this.http.put('/api/v1.0/companies/open-form-link', req);

  getOpenFormLinkByCompId = (companyId: string) =>
    this.http.get<OpenFormLink>(`/api/v1.0/companies/open-form-link/company/${companyId}`);

  checkOpenFormLink = (openFormId: string) =>
    this.http.get<void>(`/api/v1.0/companies/open-form-link/${openFormId}/active`);

  joinOpenFormLink = (openFormId: string) =>
    this.http.post(`/api/v1.0/companies/open-form-link/${openFormId}/join`, {});

  getCompanyUsers = (companyId: string, params?) => this.http.get(`/api/v1.0/companies/${companyId}/users`, { params });

  inviteCompanyUser = (companyId: string, email: string) =>
    this.http.post(`/api/v1.0/companies/${companyId}/users`, { email, id: companyId });

  removeCompanyUser = (companyId: string, userID: string) =>
    this.http.delete(`/api/v1.0/companies/${companyId}/users/${userID}`);

  removeInvitation = (companyId: string, email: string) =>
    this.http.delete(`/api/v1.0/companies/${companyId}/invite?email=${email}`);

  acceptCompanyInvite = (inviteId: string) => this.http.post(`/api/v1.0/companies/invite/${inviteId}/accept`, {});

  getMenu = (companyId: string) => this.http.get<CompanyMenu>(`/api/v1.0/companies/${companyId}/menu`);
}

import { Component, OnInit } from '@angular/core';
import { I18nService } from '@digilize/shared/feature/modules/i18n/src';
import gitVersion from '../../../../git-version';
import { ColorsAndBrandService } from './services/colors-and-brand.service';

@Component({
  selector: 'gac-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private i18nService: I18nService, private colorsAndBrandService: ColorsAndBrandService) {
    this.i18nService.init(); /* for compatibility purpose only, remove afer changing dependencies to transloco */
    this.colorsAndBrandService.init();
  }

  ngOnInit(): void {
    console.log('Version: ' + (gitVersion.tag ? gitVersion.tag : gitVersion.hash));
  }
}

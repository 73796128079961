import { Injectable } from '@angular/core';
import { ShopQuizService } from './shopQuiz.service';
import { first } from 'rxjs';
import { ShopQuizWithShop } from '@digilize/shared/definitions/src';

@Injectable({ providedIn: 'root' })
export class ColorsAndBrandService {
  constructor(private shopQuizService: ShopQuizService) {
    this.shopQuizService.shopQuiz$.pipe(first()).subscribe((shopQuiz) => {
      this.loadColors(shopQuiz);
      this.setHead(shopQuiz);
    });
  }

  init() {}

  setHead(shopQuiz: ShopQuizWithShop) {
    const link: any = document.querySelector('link[rel="icon"]');
    link.href = shopQuiz.shop.photo.url1x;
    const title: any = document.querySelector('title');
    title.innerHTML = shopQuiz.quiz.name;
    if (shopQuiz.quiz.description) {
      const description: any = document.querySelector('meta[name="description"]');
      description.setAttribute('content', shopQuiz.quiz.description);
    }
  }

  loadColors(shopQuiz: ShopQuizWithShop, element = document.documentElement) {
    if (!shopQuiz.shop.colors && !shopQuiz.colors) return;
    const {
      background,
      background_text,
      background_secondary,
      background_secondary_text,
      primary,
      primary_text,
      secondary,
      secondary_text,
    } = shopQuiz.colors || shopQuiz.shop.colors;
    element.style.setProperty(`--gac-color-background-secondary`, background_secondary);
    element.style.setProperty(`--gac-color-medium-contrast`, background);
    element.style.setProperty(`--dig-color-medium`, background_text);
    element.style.setProperty(`--dig-color-font`, primary_text);
    element.style.setProperty(`--dig-color-primary`, primary);
    element.style.setProperty(`--dig-color-primary-contrast`, primary_text);
    element.style.setProperty(`--dig-color-secondary`, secondary_text);
    element.style.setProperty(`--dig-color-secondary-contrast`, secondary);
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Media } from '@digilize/shared/definitions/src';
import { CropperPosition, ImageTransform, base64ToFile } from 'ngx-image-cropper';

@Component({
  selector: 'lib-photo-crop',
  templateUrl: './photo-crop.component.html',
  styleUrls: ['./photo-crop.component.scss'],
})
export class PhotoCropComponent implements OnInit {
  croppedImage: any = '';
  croppedImageFile: any = '';
  imageFile: any = '';
  imageURL: string = '';
  transform: ImageTransform = {};
  croppedImageSizes: { height: number; width: number } = null; // Calculated
  imgOnPageSizes: { height: number; width: number } = null;
  scale = 1;
  cropper: CropperPosition = {
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
  };
  cropperMinWidth = 0;
  cropperMinHeight = 0;
  cropperMaxWidth = 10000;
  cropperMaxHeight = 10000;
  imgMedia: Media | any;
  errors: string[] = [];
  zoomInterval: any;

  constructor(public dialogRef: MatDialogRef<PhotoCropComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    if (this.data) {
      this.imageURL = this.data.imageURL ?? this.imageURL;
      this.imageFile = this.data.imageFile ?? this.imageFile;
      this.cropperMinWidth = this.data.cropperMinWidth ?? this.cropperMinWidth;
      this.cropperMinHeight = this.data.cropperMinHeight ?? this.cropperMinHeight;
      this.cropperMaxWidth = this.data.cropperMaxWidth ?? this.cropperMaxWidth;
      this.cropperMaxHeight = this.data.cropperMaxHeight ?? this.cropperMaxHeight;
      this.imgMedia = this.data.imgMedia ?? this.imgMedia;

      if (this.imageURL && this.imageURL !== '') {
        this.getImageResolutionUrl(this.imageURL);
      } else {
        this.getImageResolution(this.imageFile);
      }
    }
  }

  getImageResolution(file) {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const img = new Image();
        img.onload = () => {
          this.imgMedia = {
            width: img.width,
            height: img.height,
          };
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  getImageResolutionUrl(url) {
    const img = new Image();
    img.onload = () => {
      const resolution = { width: img.naturalWidth, height: img.naturalHeight };
      this.imgMedia = {
        width: resolution.width,
        height: resolution.height,
      };
    };
    img.src = url;
  }

  imageCropped(event: any) {
    this.croppedImageSizes = { height: event.height, width: event.width };
    this.checkForErrors(event.height, event.width);
    this.croppedImage = event.base64;
    this.croppedImageFile = base64ToFile(this.croppedImage);
  }

  cropperReady(imgOnPageSizes) {
    // returns size of the image on the page (not real one)
    this.imgOnPageSizes = imgOnPageSizes;
  }

  startZoom(step: number): void {
    this.changeZoom(step);
    this.zoomInterval = setInterval(() => {
      this.changeZoom(step);
    }, 100);
  }

  stopZoom(): void {
    clearInterval(this.zoomInterval);
  }

  changeZoom(stepSize) {
    let newScale = this.scale + parseFloat(stepSize);
    if (newScale < 0) {
      newScale = 0;
    }
    this.scale = newScale;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  onSizeChange() {
    //  img on page size (view size) /img (real size)  ratio
    const ratio_x = this.imgOnPageSizes.width / this.imgMedia.width;
    const ratio_y = this.imgOnPageSizes.height / this.imgMedia.height;

    // image crop size (passed one) / img (real size) ratio
    let img_h_ratio = this.croppedImageSizes.height / this.imgMedia.height;
    let img_w_ratio = this.croppedImageSizes.width / this.imgMedia.width;

    // img (real size) / img on page size (view size) ratio
    let page_img_h_ratio = this.imgMedia.height / this.imgOnPageSizes.height;
    let page_img_w_ratio = this.imgMedia.width / this.imgOnPageSizes.width;

    //block height/width max size
    if (img_h_ratio > 1) {
      img_h_ratio = 1;
      setTimeout(() => {
        this.croppedImageSizes.height = this.imgMedia.height;
      });
    }
    if (img_w_ratio > 1) {
      img_w_ratio = 1;

      setTimeout(() => {
        this.croppedImageSizes.width = this.imgMedia.width;
      });
    }

    // sizes of crop (view)
    const calc_h = this.imgOnPageSizes.height * img_h_ratio;
    const calc_w = this.imgOnPageSizes.width * img_w_ratio;
    const additional_w_size = calc_w - (this.cropper.x2 - this.cropper.x1);
    const additional_h_size = calc_h - (this.cropper.y2 - this.cropper.y1);

    this.cropper.x1 = this.cropper.x1 - additional_w_size / 2;
    this.cropper.x2 = this.cropper.x2 + additional_w_size / 2;
    this.cropper.y1 = this.cropper.y1 - additional_h_size / 2;
    this.cropper.y2 = this.cropper.y2 + additional_h_size / 2;

    // calculate height/width if crop is oversized - out of the image, add extra size
    if (this.cropper.y2 > this.imgOnPageSizes.height) {
      this.cropper.y2 = this.imgOnPageSizes.height;
      this.setCropperAxis('y', ratio_y, false, page_img_h_ratio);
    }

    if (this.cropper.y1 < 0) {
      this.cropper.y1 = 0;
      this.setCropperAxis('y', ratio_y, true, page_img_h_ratio);
    }

    if (this.cropper.x2 > this.imgOnPageSizes.width) {
      this.cropper.x2 = this.imgOnPageSizes.width;
      this.setCropperAxis('x', ratio_x, false, page_img_w_ratio);
    }

    if (this.cropper.x1 < 0) {
      this.cropper.x1 = 0;
      this.setCropperAxis('x', ratio_x, true, page_img_w_ratio);
    }

    this.checkForErrors(this.croppedImageSizes.height, this.croppedImageSizes.width);
  }

  setCropperAxis(axis, ratio, belowZero, page_img_ratio) {
    const axis1 = `${axis}1`;
    const axis2 = `${axis}2`;
    const hOrW = axis === 'x' ? 'width' : 'height';
    const desired_crop_size_y = this.croppedImageSizes[hOrW] * ratio;
    const selection = this['cropper'][axis2] - this['cropper'][axis1];
    const notAddedSize = Math.abs(desired_crop_size_y - selection);
    const restOfPageSize = Math.round(
      Math.abs(this['imgOnPageSizes'][hOrW] - this['cropper'][axis2] - this['cropper'][axis1])
    );

    if (restOfPageSize > notAddedSize) {
      if (belowZero) {
        this['cropper'][axis2] = this['cropper'][axis2] + notAddedSize;
      } else {
        this['cropper'][axis1] = this['cropper'][axis1] - notAddedSize;
      }
    } else {
      if (belowZero) {
        this['cropper'][axis2] = this['imgOnPageSizes'][hOrW];
      } else {
        this['cropper'][axis1] = 0;
      }
    }

    setTimeout(() => {
      if (belowZero) {
        this.croppedImageSizes[hOrW] = Math.round(
          this.imgMedia[hOrW] - (this.imgMedia[hOrW] - this['cropper'][axis2] * page_img_ratio)
        );
      } else {
        this.croppedImageSizes[hOrW] = Math.round(this.imgMedia[hOrW] - this['cropper'][axis1] * page_img_ratio);
      }
    });
  }

  private checkForErrors(height, width) {
    if (height < this.cropperMinHeight) {
      this.addError('min_height');
    } else {
      this.removeError('min_height');
    }

    if (width < this.cropperMinWidth) {
      this.addError('min_width');
    } else {
      this.removeError('min_width');
    }

    if (height > this.cropperMaxHeight) {
      this.addError('max_height');
    } else {
      this.removeError('max_height');
    }

    if (width > this.cropperMaxWidth) {
      this.addError('max_width');
    } else {
      this.removeError('max_width');
    }
  }

  private addError(name) {
    if (!this.errors.includes(name)) {
      this.errors.push(name);
    }
  }

  private removeError(name) {
    const indexToRemove = this.errors.indexOf(name);

    if (indexToRemove !== -1) {
      this.errors.splice(indexToRemove, 1);
    }
  }

  saveCroppedImage() {
    this.dialogRef.close({
      saved: true,
      croppedImageFile: this.croppedImageFile,
      croppedImageResolution: this.croppedImageSizes,
    });
  }

  close() {
    this.dialogRef.close();
  }
}

import { NgModule } from '@angular/core';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedUtilsPipesModule } from '@digilize/shared/utils/pipes/src';
import { SharedUiComponentsModule } from '@digilize/shared/ui/components/src';
import { TranslocoModule } from '@ngneat/transloco';
import { BadgeComponent } from './badge/badge.component';
const COMPONENTS = [NavbarComponent, FooterComponent, BadgeComponent];
const MODULES = [
  FormsModule,
  ReactiveFormsModule,
  InlineSVGModule,
  SharedUtilsPipesModule,
  SharedUiComponentsModule,
  TranslocoModule,
];
@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, RouterModule, ...MODULES],
  exports: [...COMPONENTS, ...MODULES],
})
export class SharedModule {}

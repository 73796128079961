<ng-container *ngIf="shopQuiz$ | async as shopQuiz">
  <a class="logo-anchor" routerLink="/">
    <img class="logo" [src]="shopQuiz.shop.photo?.url1x" alt="logo" />
  </a>
  <nav>
    <a routerLink="/rules">{{ 'global.rules' | transloco }}</a>
    <a *ngIf="isExpired(shopQuiz.finish_answers || shopQuiz.finish)" [routerLink]="['/leaderboard', shopQuiz.id]">
      <button class="btn basic w-sm h-sm">
        {{ 'global.check_results' | transloco }}
      </button>
    </a>

    <a
      *ngIf="!isExpired(shopQuiz.finish_answers || shopQuiz.finish)"
      [routerLink]="['/bets', shopQuiz.id, shopQuiz.require_code ? 'enter-code' : 'predictions']"
    >
      <button class="btn secondary w-sm h-sm">
        {{ 'global.play_now' | transloco }}
      </button>
    </a>
  </nav>
</ng-container>

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gac-order-number',
  templateUrl: './order-number.component.html',
  styleUrls: ['./order-number.component.scss'],
})
export class OrderNumberComponent implements OnInit {
  @Input() order: number;
  constructor() {}

  ngOnInit() {}
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthSharedDataService {
  private signUpCustomHtmlCodeSource = new BehaviorSubject<string>('');
  signUpCustomHtmlCode$ = this.signUpCustomHtmlCodeSource.asObservable();
  private signUpCustomMethodToCallSource = new BehaviorSubject<() => void>(() => {});
  signUpCustomMethodToCall$ = this.signUpCustomMethodToCallSource.asObservable();

  constructor() {}

  setHtmlCodeAndMethod(htmlCode: string, method: () => void) {
    this.signUpCustomHtmlCodeSource.next(htmlCode);
    this.signUpCustomMethodToCallSource.next(method);
  }
}

import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { HttpClient } from '@angular/common/http';
import { forkJoin, map, Observable } from 'rxjs';
import { getTranslationSuffix } from '@digilize/shared/utils/helpers/src';
import { ENV_CONFIG } from '@digilize/shared/utils/tokens/src';
import { EnvConfig } from '@digilize/shared/definitions/src';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);
  private envConfig: EnvConfig = inject(ENV_CONFIG);
  getTranslation(lang: string) {
    let obs: Observable<any>[] = [];
    const SUFFIX = getTranslationSuffix(this.envConfig);
    obs.push(this.http.get<Translation>(`/assets/i18n/common/${lang}${SUFFIX}`));
    obs.push(this.http.get<Translation>(`/assets/i18n/${lang}${SUFFIX}`));
    obs.push(this.http.get<Translation>(`/assets/i18n/common/alerts-${lang}${SUFFIX}`));
    return forkJoin([...obs]).pipe(
      map((translationsArr) => {
        const translations = translationsArr.reduce((acc, curr) => {
          return { ...acc, ...curr };
        }, {});
        return translations;
      })
    );
  }
}

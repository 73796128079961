<div class="container">
  <div class="close-icon" (click)="close()"><img src="/assets/icons/close-icon.svg" /></div>
  <div class="row m-0">
    <div class="col-12">
      <h2 class="page-title">{{ 'cropping_image.cropping_image' | translate }}</h2>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-12 middle-container">
      <image-cropper
        #imageCropper
        [imageFile]="imageFile"
        [imageURL]="imageURL"
        [maintainAspectRatio]="false"
        [(transform)]="transform"
        [cropper]="cropper"
        [cropperMinWidth]="cropperMinWidth"
        [cropperMinHeight]="cropperMinHeight"
        [cropperMaxWidth]="cropperMaxWidth"
        [cropperMaxHeight]="cropperMaxHeight"
        format="png"
        (imageCropped)="imageCropped($event)"
        (cropperReady)="cropperReady($event)"
        output="blob"
      ></image-cropper>
    </div>

    <div class="col-12 img-data">
      <div class="img-scale">
        <span>{{ 'cropping_image.scale' | translate }}: {{ scale * 100 | number }}%</span>
        <button (mousedown)="startZoom(+0.01)" (mouseup)="stopZoom()">
          {{ 'cropping_image.zoom_in' | translate }}
        </button>
        <button (mousedown)="startZoom(-0.01)" (mouseup)="stopZoom()">
          {{ 'cropping_image.zoom_out' | translate }}
        </button>
      </div>

      <div *ngIf="imgMedia.width !== 0 && imgMedia.height !== 0" class="img-res">
        {{ 'cropping_image.image_resolution' | translate }}: {{ imgMedia?.width }} X {{ imgMedia?.height }} px
      </div>
      <div *ngIf="cropperMinWidth !== 0 && cropperMinHeight !== 0" class="img-res">
        {{ 'cropping_image.min_img_resolution' | translate }}: {{ cropperMinWidth }} X {{ cropperMinHeight }} px
      </div>
      <div *ngIf="cropperMaxWidth !== 0 && cropperMaxHeight !== 0" class="img-res">
        {{ 'cropping_image.max_img_resolution' | translate }}: {{ cropperMaxWidth }} X {{ cropperMaxHeight }} px
      </div>
      <div class="crop-res">
        <label for="widthInput">{{ 'cropping_image.cropped_area_width_height' | translate }}:</label>
        <input id="widthInput" type="text" [(ngModel)]="croppedImageSizes.width" (ngModelChange)="onSizeChange()" /> X
        <input id="heightInput" type="text" [(ngModel)]="croppedImageSizes.height" (ngModelChange)="onSizeChange()" />px
        <br />
      </div>

      <div class="error-msg">
        <div class="error-info" *ngFor="let error of errors">{{ 'cropping_image.' + error | translate }}</div>
      </div>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-12 bottom-container">
      <div class="bottom-right">
        <ul>
          <li>
            <button class="btn btn-black" [disabled]="errors.length > 0" (click)="saveCroppedImage()">
              {{ 'global.save' | translate }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

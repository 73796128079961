import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DataApiResponseLegacy,
  QuizSessionAnswerRequest,
  QuizSessionState,
  ShopQuiz,
  ShopQuizPublic,
  ShopQuizSessionBulkStateModel,
  ShopQuizSessionPlace,
  ShopQuizWithShop,
} from '@digilize/shared/definitions/src';

@Injectable({
  providedIn: 'root',
})
export class ShopQuizzesApiService {
  readonly shopQuizzesBaseUrl = '/api/v1.0/shop-quizzes';
  constructor(private http: HttpClient) {}

  getShopQuizzes = (params) => this.http.get(`${this.shopQuizzesBaseUrl}`, { params });

  getShopQuiz = (quizId) => this.http.get<ShopQuiz>(`${this.shopQuizzesBaseUrl}/${quizId}`);

  assignQuiz = (quiz) => this.http.post(`${this.shopQuizzesBaseUrl}`, quiz);

  editShopQuiz = (quiz) => this.http.put(`${this.shopQuizzesBaseUrl}`, quiz);

  removeShopQuiz = (params) => this.http.get(`${this.shopQuizzesBaseUrl}`, { params });

  updateShopQuizStatus = (params) => this.http.get(`${this.shopQuizzesBaseUrl}`, { params });

  getPublicShopQuiz = (id: string) => this.http.get<ShopQuiz>(`${this.shopQuizzesBaseUrl}/${id}/public`);

  playShopQuizStatus = (id, params: QuizSessionAnswerRequest) =>
    this.http.post<QuizSessionState>(`${this.shopQuizzesBaseUrl}/${id}/play`, params);

  getShopQuizPublic = (id: string, status: boolean = false) => {
    return this.http.get<ShopQuizPublic>(`${this.shopQuizzesBaseUrl}/${id}/game`, { params: { status } });
  };

  sendPublicShopQuiz = (id: string, params: ShopQuizSessionBulkStateModel) =>
    this.http.post(`${this.shopQuizzesBaseUrl}/${id}/game/play`, { ...params });

  validatePublicShopQuiz = (id: string, code: string) =>
    this.http.post(`${this.shopQuizzesBaseUrl}/validate/${id}/game`, { code });

  getShopQuizRanking = (id: string, params: { length: number; start: number; id: string }) =>
    this.http.get<DataApiResponseLegacy<ShopQuizSessionPlace[]>>(`${this.shopQuizzesBaseUrl}/${id}/ranking`, {
      params: params,
    });

  resolverByDomain(domain: string) {
    return this.http.get<ShopQuizWithShop>(`${this.shopQuizzesBaseUrl}/by-domain/${domain}`);
  }
}

import { Component, OnInit } from '@angular/core';
import { ShopQuizService } from '../../services/shopQuiz.service';
import { Observable } from 'rxjs';
import { ShopQuizWithShop } from '@digilize/shared/definitions/src';

@Component({
  selector: 'gac-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  shopQuiz$: Observable<ShopQuizWithShop> = this.shopQuizService.shopQuiz$;
  constructor(private shopQuizService: ShopQuizService) {}

  isExpired(finish): boolean {
    const secondsPast = new Date(finish).getTime() - new Date().getTime();
    return secondsPast < 0;
  }

  ngOnInit() {}
}

<div class="row" *ngIf="shopQuiz$ | async as shopQuiz">
  <img class="logo" [src]="shopQuiz.shop.photo?.url1x" alt="logo" />
  <div class="contact">
    <h5>{{ 'footer.contact_us' | transloco }}</h5>
    <ul>
      <li>{{ shopQuiz.shop.street }} {{ shopQuiz.shop.house_number }}</li>
      <li>{{ shopQuiz.shop.post_code }}, {{ shopQuiz.shop.city }}</li>
      <li>{{ shopQuiz.shop.email }}</li>
      <li>{{ shopQuiz.shop.phone }}</li>
    </ul>
  </div>
  <div>
    <h5>{{ 'footer.follow_us' | transloco }}</h5>
    <a [href]="shopQuiz.shop.facebook">
      <span class="margin-right" inlineSVG="/icons/Facebook_white.svg"></span>
    </a>
    <a [href]="shopQuiz.shop.instagram">
      <span inlineSVG="/icons/Instagram_white.svg"></span>
    </a>
  </div>
</div>

import { EnvConfig } from '@shared/definitions';

export const environment: EnvConfig = {
  production: true,
  staging: false,
  development: false,
  api: 'https://admin.flixit.nl',
  facebookAppId: '',
  instrumentationKey: '',
  recaptchaSiteKey: '',
};
